import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  MainHeading,
  SubHeadingStyled,
  TextStyled,
} from "../Styled/Elements";
import { PagesBg } from "../Styled/AllImages";
import { ContactButton } from "../Styled/CustomElements";
import PagesHeader from "../header/PagesHeader";
import Footer from "../footer/Footer";
import PagesFooter from "../footer/PagesFooter";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <ErrorPageWrapper style={{ backgroundImage: `url(${PagesBg})` }}>
        <PagesHeader />
        <Box>
          <img
            src="https://assets.codepen.io/1538474/404.svg"
            className="logo-404 my-4"
          />
          <Box className="error-info-card py-3 px-5">
            <SubHeadingStyled
              size="36px"
              color="var(--themeColor)"
              className="title mb-2"
              weight="600"
            >
              {t('oh_no')}
            </SubHeadingStyled>
            <TextStyled className="subtitle" size="18px" color="var(--white)">
              {t('page_404_error')}
            </TextStyled>
            <Box className="d-flex justify-content-center">
              <ContactButton
                label={t("Home")}
                onClick={() => navigate("/")}
                clName={"mb-3"}
              />
            </Box>
            {/* <button
          className="home-btn"
        >
          Home
        </button> */}
          </Box>
        </Box>
      </ErrorPageWrapper>
      <PagesFooter />
    </React.Fragment>
  );
};

export default ErrorPage;

const ErrorPageWrapper = styled.div`
  width: 100%;
  height: 85vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  $nunito-font: "Nunito", sans-serif;

  // mixins
  @mixin breakpoint($point) {
    @if $point==mobile {
      @media (max-width: 480px) and (min-width: 320px) {
        @content;
      }
    }
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    background-attachment: fixed;
    overflow: hidden;
  }

  .logo-404 {
    margin-left: auto;
    margin-right: auto;
    width: 30vmin;

    @include breakpoint(mobile) {
      top: 45vmin;
    }
  }

  /* .subtitle {
    color: var(--white) !important;
    -webkit-text-fill-color: var(--white) !important;
    font-weight: 400;
    text-align: center;
    font-size: 3.5vmin;
    margin-top: -1vmin;
    margin-bottom: 9vmin;
  } */

  .error-info-card {
    background: var(--darkGradientStraight);
    border: 1px solid var(--borderColor);
  }
`;
