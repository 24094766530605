import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, LazyImage } from "../Styled/Elements";
import styled from "styled-components";
import { LeftArrow, RightArrow } from "../Styled/AllImages";

const ImageSlider = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState("");

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    nextArrow: <RightArrow className="slider-arrow" />,
    prevArrow: <LeftArrow className="slider-arrow" />,
    // afterChange: (index) => setSelectedImage(images[index]),
  };

  useEffect(() => {
    if (images.length > 0) setSelectedImage(images[0]);
  }, [images]);

  return (
    <ImageSliderStyled>
      <Box className="img-prev-container mb-4">
        <LazyImage
          src={selectedImage}
          alt="Selected"
          className="slider-img-prev"
        />
      </Box>
      <Box className="slider-container mx-4">
        <Slider {...settings}>
          {images.map((img, index) => (
            <Box
              key={index}
              className="slider-img-container"
              onClick={() => setSelectedImage(img)}
            >
              <img src={img} className="slider-img" alt={`Slide ${index}`} />
            </Box>
          ))}
        </Slider>
      </Box>
    </ImageSliderStyled>
  );
};

export default ImageSlider;

const ImageSliderStyled = styled.div`
  .slick-arrow {
    height: 100%;
    background-color: var(--bgSecondary);
    padding: 5px;
  }
  .lazy-load-image-background {
    width: 100%;
  }
  .img-prev-container{
    border: 1px solid var(--secBorderColor);
  }
  .slider-img-prev {
    width: 100%;
    height: 532px;
    object-fit: contain;
    object-position: top;
  }
  .slider-img-container {
  }
  .slider-container {
    /* margin: 0 15px; */
  }
  .slider-img {
    width: 100%;
    height: 117px;
    object-fit: cover;
    object-position: top;
  }
  .slick-slide img {
    display: initial;
  }
  
`;
