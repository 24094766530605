import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
export const termsApi = createApi({
    reducerPath: "termsApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_URL}),
    endpoints: (builder) => ({
        getTerms: builder.query({
            query: (lang) => ({
                method: "GET",
                url: `terms`,
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        })
    })
})

export const {useGetTermsQuery} = termsApi