import styled from "styled-components";

export const AboutWrapper = styled.section`
    img.aboutBg{
        mix-blend-mode: soft-light;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .aboutContentCol{
        padding-right: 90px;
    }
    .aboutStripe{
        position: absolute;
        bottom: -170px;
        right: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: none;
        display: none;
    }
    .aboutStripeEn{
        display: block;
    }
    .aboutImgCol{
        display: flex;
        gap: 15px;
        .aboutImgContainer{
            position: relative;
            span{
                height: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, .2);
                -webkit-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, .2);
	            -moz-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, .2);
            }
            .aboutImgTxt{
                position: absolute;
                bottom: 20px;
                left: 10px;
                right: 10px;
                text-align: center;
                z-index: 1;
                color: var(--white);
                font-size: 18px;
                line-height: 25px;
                font-family: var(--lightMedium);
                text-shadow: 0px 2px 5px rgba(0, 0, 0, .35);
                -webkit-text-shadow: 0px 2px 5px rgba(0, 0, 0, .35);
	            -moz-text-shadow: 0px 2px 5px rgba(0, 0, 0, .35);
            }
        }
    }
    @media (max-width: 1399px) {
        .aboutContentCol {
            padding-right: 40px;
        }
    }
    @media (max-width: 1199px) {
        .aboutContentCol {
            padding-right: calc(var(--bs-gutter-x) * .5);
        }
        .aboutImgCol {
            display: flex;
            gap: 5px;
        }
        .main-heading {
            margin-bottom: 10px;
        }
        .commonTxt{
            margin-bottom: 0;
        }
        
    }
    @media (max-width: 991px) {
        padding-top: 60px;
        padding-bottom: 120px;
        .aboutImgCol{
            margin-bottom: 40px;
            justify-content: center;
        }
        .aboutStripe {
            max-width: 3500px;
            bottom: -120px;
        }
        .aboutContentCol {
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
        }
    }
    @media (max-width: 575px) {
        .aboutImgCol .aboutImgContainer .aboutImgTxt {
            left: 3px;
            right: 3px;
            font-size: 15px;
            line-height: 20px;
        }
    }
    @media (max-width: 360px) {
        .aboutImgCol {
            display: none;
        }
    }


`
export const ExpertiseWrapper = styled.section`
    z-index: 18;
    padding-bottom: 70px;
    .expertiseBg{
        mix-blend-mode: soft-light;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .expertiseRow{
        padding-top: 100px;
    }
    .sliderWrapper{
        margin-top: 10px;
    }
    .slide-item{
        display: flex;
        border: 1px solid #136750;
        padding: 25px;
        gap: 10px;
        align-items: center;
        background: var(--darkGradient);
    }
    
    .slick-slide > div{
        margin: 0 6px;
    }
    .slideTitle{
        display: flex;
        font-family: var(--medium);
        font-size: 22px;
        color:  var(--white);
        line-height: normal;
        margin-bottom: 0;
    }
    .slide-item .contactWrapper{
        button{
            height: 44px;
            font-family: var(--regular);
        }
    }
    .afImg {
        width: 300px;
        height: 341px;
        object-fit: contain;
    }
        
    @media (max-width: 1399px) {
        .slide-item {
            padding: 15px !important;
        }
        /* .afImg {
            min-width: 250px;
            max-width: 250px;
        } */
    }
    @media (max-width: 1199px) {
        .afImg {
            min-width: 220px;
            max-width: 220px;
        }
    }
    @media (max-width: 991px) {
        .slide-item {
            justify-content: space-between;
        }
        .expertiseRow {
            padding-top: 30px;
        }
    }
    @media (max-width: 767px) {
        .expertiseRow {
            padding-top: 10px;
        }
    }
    @media (max-width: 575px) {
        .slide-item {
            flex-wrap: wrap;
            justify-content: center;
            .afImg{
                margin-top: 15px;
            }
        }
        .slide-text{
            display: flex;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            p{
                text-align: center;
                justify-content: center;
            }
        }
    }
    
    

`
