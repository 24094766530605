import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
export const checkoutApi = createApi({
    reducerPath: "checkoutApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_URL}),
    endpoints: (builder) => ({
        createSession: builder.mutation({
            query: ({lang, data}) => ({
                method: "POST",
                url: `telr-create-session`,
                body: data,
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        }),
        checkStatus: builder.mutation({
            query: ({lang, data}) => ({
                method: "POST",
                url: `telr-check-payment-status`,
                body: data,
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        })
    })
})

export const {useCreateSessionMutation, useCheckStatusMutation} = checkoutApi