import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  LazyImage,
  MainHeading,
  SpanStyled,
  SubHeading,
  TextStyled,
} from "../../Styled/Elements";
import {
  AboutBg,
  AboutImg,
  AboutImg1,
  AboutImg2,
  AboutImg3,
  AboutImgAr,
  ArrowRight,
  ArrowRight2,
  ExpertiseBg,
  MoveOnBg,
  MoveOnImg,
  Stripe,
} from "../../Styled/AllImages";
import { ContactButton } from "../../Styled/CustomElements";
import { expertiseList, scrollImages } from "../../data/Data";
import { MoveOnWrapper } from "./styled";
import { useNavigate } from "react-router-dom";

const MoveOn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 2,
    swipeToSlide: true,
    autoplay: false,
    speed: 1000,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <MoveOnWrapper
        className="container-fluid common-space moveOnFluid"
        id="moveOn"
      >
        {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
        <Box className="baseLayer baseLayerMoveOn">
          <img className="moveOnBg" src={MoveOnBg} />
        </Box>
        <Container>
          <Box className="moveOnRow row">
            <Col md={12}>
              <Box className="moveOnWrapper">
                <SpanStyled className="moveSubHeading">
                  {t("Timeto")}
                </SpanStyled>
                <SpanStyled className="moveMainHeading">
                  {t("MoveOn")}
                </SpanStyled>
                <ContactButton
                  size="14px"
                  icon={ArrowRight2}
                  label={t("GetStarted")}
                  bg="var(--darkColor)"
                  color="var(--themeColor)"
                  onClick={() => navigate('/our-packages')}
                />
                <img className="moveOnImg" src={MoveOnImg} />
              </Box>
            </Col>
          </Box>
        </Container>
      </MoveOnWrapper>
    </>
  );
};

export default MoveOn;
