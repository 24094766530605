import { configureStore } from "@reduxjs/toolkit";
import { productsApi } from "./services/productsApi";
import { packagesApi } from "./services/packagesApi";
import { checkoutApi } from "./services/checkoutApi";
import { settingsApi } from "./services/settingsApi";
import { trainersApi } from "./services/trainersApi";
import { contactApi } from "./services/contactApi";
import { termsApi } from "./services/termsApi";
import { privacyApi } from "./services/privacyApi";

const { homepageApi } = require("./services/homepageApi");

const store = configureStore({
  reducer: {
    [homepageApi.reducerPath]: homepageApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [packagesApi.reducerPath]: packagesApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [trainersApi.reducerPath]: trainersApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [termsApi.reducerPath]: termsApi.reducer,
    [privacyApi.reducerPath]: privacyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(homepageApi.middleware)
      .concat(productsApi.middleware)
      .concat(packagesApi.middleware)
      .concat(checkoutApi.middleware)
      .concat(settingsApi.middleware)
      .concat(trainersApi.middleware)
      .concat(contactApi.middleware)
      .concat(termsApi.middleware)
      .concat(privacyApi.middleware)
});
export default store;
