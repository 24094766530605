import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_URL}),
    endpoints: (builder) => ({
        getProductDetails: builder.query({
            query: ({id, lang}) => ({
                method: "GET",
                url: `product-detail/${id}`,
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        })
    })
})

export const {useGetProductDetailsQuery} = productsApi