import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
export const privacyApi = createApi({
    reducerPath: "privacyApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_URL}),
    endpoints: (builder) => ({
        getPrivacyPolicy: builder.query({
            query: (lang) => ({
                method: "GET",
                url: `privacy`,
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        })
    })
})

export const {useGetPrivacyPolicyQuery} = privacyApi