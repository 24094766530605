import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 260px;
    border-radius: 32px 32px 0 0;
    background: var(--gradient);
    top: -260px;
    z-index: -2;
  }
  .contact-heading {
    line-height: 0.9;
  }
  iframe {
    width: 100%;
    height: 532px;
  }
  @media (max-width: 992px) {
    .contact-box {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 470px) {
    .contact-heading {
      font-size: 40px;
    }
  }
`;
export const ContactFormWrapper = styled.div`

  .input-field::placeholder {
    font-weight: 100;
    font-family: ${(props) =>
      props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
    color: var(--textPalceholder);
  }
  .loading-btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: var(--regular);
  }
  .contact-social-links {
    position: relative;
    left: 15px;
  }

  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-repeat: no-repeat;
  }

  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 40px;
    color: var(--textPalceholder);
    content: "Upload Attechment";
  }
`;
