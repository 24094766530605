import React from "react";
import styled from "styled-components";
import { LazyImage } from "../components/Styled/Elements";
import { Whatsapp } from "../components/Styled/AllImages";

const ChangeButton = () => {
	/* const WhatsApp = () => {
		const phoneNumber = '';
		const message = 'Hello,Own Your Health';
		const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
		window.open(whatsappURL, '_blank');
	}; */

	return (
		<>
			<ButtonBox>
				<span className="whatsapp">
					<LazyImage src={Whatsapp} />
				</span>

			</ButtonBox>
		</>
	);
};
const ButtonBox = styled.div`
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  .whatsapp {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: none;
  }
  .change-Btn {
    position: fixed;
    bottom: 0;
    right: 0;

  }
  .english_icon {
    // width: 50px;
    // height: 50px;
    border-radius: 50%;
    // margin: 30px 15px;
    cursor: pointer;
    z-index: 1;
  }
  .arabic_icon {
    // width: 70px;
    // height: 70px;
    border-radius: 50%;
    // margin: 20px 15px;
    cursor: pointer;
    z-index: 1;
  }
`;

export default ChangeButton;
