import React, { useState } from "react";
import { ContactFormWrapper } from "./styled";
import { useForm } from "react-hook-form";
import { Box, LabelStyled, MainHeading, TextStyled } from "../../components/Styled/Elements";
import { ButtonWithIcon, ContactButton } from "../../components/Styled/CustomElements";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { ArrowRight2, Message, Phone, Phone2 } from "../../components/Styled/AllImages";
import { usePostContactUsMutation } from "../../store/services/contactApi";
const ContactForm = () => {
  const { t, i18n: {language} } = useTranslation();
  const [phone, setPhone] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      comments: "",
    },
  });

  const [postContact, {isLoading}] = usePostContactUsMutation()
  
  const handlePhoneChange = (e) => {
    // Allow only positive numbers
    const input = e.target.value;
    if (!isNaN(input) && input >= 0) {
      setPhone(input);
    }
  };
  const getApi = async (formData) => {
    try {
      const {data, error} = await postContact({formData, lang: language})
      
      if (data?.code === 200) {
        reset({
          name: "",
          email: "",
          phone_number: "",
          comments: "",
        });
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(error?.data?.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      
      toast.error(error?.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const onSubmit = async (data) => {
    getApi(data);
  };

  return (
    <>
      <ContactFormWrapper lang={language}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="contact-head text-center">
            <MainHeading
              className="lightOrange_gradient contact-heading"
              size="48px"
              weight="bolder"
            >
              {t("Contact_Us")}
            </MainHeading>
          </Box>
          <Row className="mt-3">
            {/* <Col lg={6} md={6} sm={12}>
              <Box className="d-flex">
                <Phone2 className="mt-1"/>
                <TextStyled className="mx-3">0537070033</TextStyled>
              </Box>
            </Col> */}
            <Col lg={6} md={6} sm={12}>
            <Box className="d-flex">
                <Message className="mt-1"/>
                <TextStyled className="mx-3">info@ownyou-health.com</TextStyled>
              </Box>
            </Col>
          </Row>
          <Box className="field-box">
            <LabelStyled color="var(--text)" weight="lighter">{t("name")}</LabelStyled>
            <input
              className="input-field"
              placeholder={t("namePlaceholder")}
              {...register("name", { required: true })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === "required" && (
              <p className="error-message" role="alert">
                {t("name_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--text)" weight="lighter">{t("email")}</LabelStyled>
            <input
              className="input-field"
              placeholder={t("emailPlaceholder")}
              type="email"
              {...register("email", { required: true })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && (
              <p className="error-message" role="alert">
                {t("email_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--text)" weight="lighter">{t("phone")}</LabelStyled>
            <input
              className="input-field"
              name="phone_number"
              placeholder={t("phonePlaceholder")}
              type="text"
              value={phone}
              {...register("phone_number", { required: true })}
              aria-invalid={errors.phone_number ? "true" : "false"}
              onChange={handlePhoneChange}
            />
            {errors.phone_number && (
              <p className="error-message" role="alert">
                {t("phone_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--text)" weight="lighter">{t("comments")}</LabelStyled>
            <textarea
              className="input-field"
              placeholder={t("commentPlaceholder")}
              cols="30"
              rows="5"
              {...register("comments", { required: true })}
              aria-invalid={errors.comments ? "true" : "false"}
            />
            {errors.comments && (
              <p className="error-message" role="alert">
                {t("message_error")}
              </p>
            )}
          </Box>
          <Box className="d-flex justify-content-end my-3 mx-2 contact-btn">
            {isLoading ? (
              <p className="loading-btn">
                <span className="spinner"></span>
              </p>
            ) : null}
            <ContactButton
              type="submit"
              label={t("Send")}
              icon={ArrowRight2}
            />
          </Box>
        </form>
      </ContactFormWrapper>
    </>
  );
};

export default ContactForm;
