import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    postContactUs: builder.mutation({
      query: ({ data, lang }) => ({ 
        method: "POST",
        url: `contact-us`,
        headers: {
          "api-key": API_KEY,
          "X-localization": lang,
        },
        body: data
      }),
    }),
  }),
});

export const { usePostContactUsMutation } = contactApi;
