import styled from "styled-components";

export const FaqsWrapper = styled.section`
  .item-wrapper {
    padding-right: 15px;
  }
  .item-container {
    background: var(--darkGradientStraight);
    padding: 0;
    margin: 8px 0;
    border: 1px solid #136750;
    cursor: pointer;
  }
  .item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .item-box-heading {
      color: var(--themeColor);
      font-size: 16px;
      font-family: var(--lightMedium);
    }
    .item-box-text {
      color: var(--text2);
      font-size: 16px;
      font-family: var(--book);
      margin: 0;
      padding: 0 20px 20px 20px;
    }
    & > svg {
      [stroke] {
        stroke: var(--themeColor);
      }
      width: 16px;
      height: 16px;
    }
  }

  .more-btn{
    cursor: pointer;
  }

  .active-item-box {
    background: var(--darkGradientStraight);
    .item-box-heading {
      /* padding-bottom: 10px; */
    }
    .item-box-text {
      color: var(--text2);
      font-size: 16px;
      font-family: var(--book);
      margin: 0;
      padding: 0 20px 20px 20px;
    }
    & .border-bottom-line {
      border-bottom: 1px solid #149f72;
    }
    & > .item-box {
      color: var(--white);
      position: relative;
    }
  }
  @media (max-width: 991px) {
    padding-top: 10px;
  }
`;
