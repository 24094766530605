
import React, { useEffect } from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, } from '../../Styled/Elements'
import { ArrowRight, BannerBg, BannerManAr, BannerManEn, ParallexBg, Star1 } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import { useNavigate } from 'react-router-dom'

const Banner = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let scrollY = window.scrollY;
            document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
        });
    }, []);


    return (
        <BannerWrapper className='banner' id='home'>
            <Row>
                <Col className='position-relative'>
                    <Box className='header-main'>
                        <Container>
                            <Box className='bannerContentWrapper'>
                                <Box className='banner-content'>
                                    <img src={BannerManEn} className='bannerMan bannerManEn' />
                                    <img src={BannerManAr} className='bannerMan bannerManAr' />
                                    <HeadingStyled className='bannerHeading caption-sub-heading' color="var(--white)">{t('Step_into')}</HeadingStyled>
                                    <HeadingStyled className='bannerHeading caption-heading-main' color="var(--themeColor)" lh='1'>{t('Healthier')}</HeadingStyled>
                                    <HeadingStyled className='bannerHeading caption-snd-heading' color="var(--white)" lh='1'>{t('Tomorrow')}</HeadingStyled>
                                    <ContactButton icon={ArrowRight} label={t('FindYourPackages')} onClick={() => navigate('/our-packages')} />
                                </Box>
                                

                            </Box>
                        </Container>
                    </Box>
                </Col>
            </Row>
        </BannerWrapper>
    )
}

export default Banner