import React, { useEffect, useState } from "react";
import { OrderSummaryStyled } from "./styled";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SubHeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import { useTranslation } from "react-i18next";

const OrderSummary = ({
  productId,
  packageId,
  productDetails,
  packageDetails,
  settingsData,
  amountSummary
}) => {
  const PACKAGE_ASSET_URL = process.env.REACT_APP_PACKAGE_ASSET_URL;
  const PRODUCT_ASSET_URL = process.env.REACT_APP_PRODUCTS_ASSET_URL;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  console.log(amountSummary, settingsData);
  

  return (
    <OrderSummaryStyled lang={language}>
      <Box>
        <HeadingStyled size="24px" weight="500" className="mb-3">
          {t("order_summary")}
        </HeadingStyled>
        <Box className="w-100">
          <Box className="d-flex flex-sm-row flex-column">
            <Box className="summary-img">
              <LazyImage
                src={`${PRODUCT_ASSET_URL}/${productDetails?.main_image}`}
              />
            </Box>
            <Box className="w-100 mx-0 mx-sm-3">
              <Box className="d-flex justify-content-between mt-sm-0 mt-2">
                <SubHeadingStyled size="16px" weight="500">
                  {productDetails?.name}
                </SubHeadingStyled>
                <SubHeadingStyled
                  size="16px"
                  weight="500"
                  className="item-price"
                >
                  {productDetails?.price} {t("sar")}
                </SubHeadingStyled>
              </Box>
              <Box>
                <Box className="d-flex mt-3">
                  <Box>
                    <TextStyled size="16px" className="mb-1" weight="500">
                      {t("size")}:
                    </TextStyled>
                  </Box>
                  <Box>
                    <TextStyled
                      size="16px"
                      color="var(--white)"
                      weight="400"
                      className="mb-1 mx-3"
                    >
                      {productDetails?.size}
                    </TextStyled>
                  </Box>
                </Box>
                <Box className="d-flex">
                  <Box>
                    <TextStyled size="16px" className="mb-1" weight="500">
                      {t("quantity")}:
                    </TextStyled>
                  </Box>
                  <Box>
                    <TextStyled
                      size="16px"
                      color="var(--white)"
                      weight="400"
                      className="mb-1 mx-3"
                    >
                      1
                    </TextStyled>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {packageId && packageDetails && (
          <Box className="mt-3">
            <Box className="d-flex flex-sm-row flex-column">
              <Box className="summary-img">
                <LazyImage
                  src={`${PACKAGE_ASSET_URL}/${packageDetails?.image}`}
                />
              </Box>
              <Box className="mx-0 mx-sm-3 w-100">
                <Box className="d-flex justify-content-between mt-sm-0 mt-2">
                  <SubHeadingStyled size="16px" weight="500">
                    {packageDetails?.name}
                  </SubHeadingStyled>
                  <SubHeadingStyled
                    size="16px"
                    weight="500"
                    className="item-price"
                  >
                    {packageDetails?.price} {t("sar")}
                  </SubHeadingStyled>
                </Box>
                <Box className="d-flex mt-3">
                  <Box>
                    <TextStyled size="16px" className="mb-1" weight="500">
                      {t("duration")}:
                    </TextStyled>
                  </Box>
                  <Box>
                    <TextStyled
                      size="16px"
                      color="var(--white)"
                      weight="400"
                      className="mb-1 mx-3"
                    >
                      {packageDetails?.duration} {t("weeks")}
                    </TextStyled>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box className="overview-box mt-4 py-3 px-4">
          <SubHeadingStyled
            size="20px"
            weight="500"
            className="overview-head pb-3"
          >
            {t("overview")}
          </SubHeadingStyled>
          <Box className="d-flex justify-content-between mt-3 pb-3 overview-details">
            <Box>
              <TextStyled size="16px" className="mb-1" weight="500">
                {t("subtotal")}:
              </TextStyled>
              <TextStyled size="16px" className="mb-1" weight="500">
                {t("tax")}:
              </TextStyled>
              <TextStyled size="16px" className="mb-1" weight="500">
                {t("delivery_cost")}:
              </TextStyled>
            </Box>
            <Box>
              <TextStyled
                size="16px"
                color="var(--white)"
                weight="400"
                className="mb-1 justify-content-end"
              >
                {amountSummary?.sub_total} {t("sar")}
              </TextStyled>
              <TextStyled
                size="16px"
                color="var(--white)"
                weight="400"
                className="mb-1 justify-content-end"
              >
                {amountSummary?.vat} {t("sar")}
              </TextStyled>
              <TextStyled
                size="16px"
                color="var(--white)"
                weight="400"
                className="mb-1 justify-content-end"
              >
                {settingsData ? settingsData.delivery_charges : "0"} {t("sar")}
              </TextStyled>
            </Box>
          </Box>
          <Box className="d-flex justify-content-between mt-3">
            <SubHeadingStyled size="20px" weight="500">
              {t("order_total")}
            </SubHeadingStyled>
            <SubHeadingStyled size="16px" weight="500">
              {amountSummary?.grand_total} {t("sar")}
            </SubHeadingStyled>
          </Box>
        </Box>
      </Box>
    </OrderSummaryStyled>
  );
};

export default OrderSummary;
