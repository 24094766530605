import { Box, Span } from "../Styled/Elements";
import { HeaderContainer } from "./Styled";
import { PageNavConfig } from "../data/Data";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsJustify, BsX } from "react-icons/bs";
import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Logo, Phone } from "../Styled/AllImages";
import { ButtonWithIcon } from "../Styled/CustomElements";
import ChangeButton from "../../Languages/ChangeButton";
import LangChangeButton from "../../Languages/LangChangeButton";

const PagesHeader = () => {
  const navigate = useNavigate();
  const WhatsApp = () => {
    const phoneNumber = "+966533703343";
    const message = "Hello,Star 1 Academy";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const [toggle, setToggle] = useState(false);

  const toMainPage = (label, isLink, to) => {
    handleToggle();
    if (isLink) {
      navigate(to);
    } else navigate(`/?section=${label}`);
  };
  const handleToggle = () => {
    setToggle(!toggle);
    const element = document.querySelector(".nav-bar-list");
    element.classList.toggle("showToggle");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <HeaderContainer
        className={`${isSticky ? "sticky" : ""} container-fluid`}
      >
        <Container>
          <Row>
            <Box className="nav-bar">
              <Box className="logo" onClick={() => navigate("/")}>
                <Logo />
                {/* <FoooterLogo /> */}
              </Box>
              <Box className="nav-bar-list">
                {PageNavConfig.map((item, key) => (
                  <Box key={key}>
                    <Span
                      activeClass="active"
                      onClick={() => toMainPage(item.label, item.link, item.to)}
                    >
                      {t(item.label)}
                    </Span>
                  </Box>
                ))}
              </Box>
              <Box className="d-flex align-items-center whtLngWrapper">
                {/* <Box className='Social-links'>
                                    {
                                        socialIcon.map((list, key) => (
                                            <Box key={key} className='contact-links'>
                                                <a href={list.link} target="_blank" rel="noopener noreferrer">
                                                    {list.img}
                                                </a>
                                            </Box>
                                        ))
                                    }
                                </Box> */}
                {/* <ButtonWithIcon
                  className="headerBtn"
                  clName="headerBtnWrapper"
                  icon={Phone}
                  label="+966 963852741"
                  onClick={WhatsApp}
                /> */}
                <LangChangeButton />
                <span className="toggleBtn" onClick={handleToggle}>
                  {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                </span>
              </Box>
            </Box>
          </Row>
        </Container>
        <ChangeButton />
      </HeaderContainer>
    </>
  );
};

export default PagesHeader;
