import styled from 'styled-components'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding-top: 12px;
    padding-bottom: 12px; 
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 30px;
    }
    .nav-bar-list a, .nav-bar-list span{
        color:var(--white);
        position: relative;
        font-size: 15px;
        /* font-family: var(--regular); */
        cursor: pointer;
        transition: all .4s ease-in-out;
        /* &.active{
            font-family: var(--lightMedium);
        } */
        
        &:hover, &.active, &:focus{
            color:var(--themeColor);
           
            &::after, &.active::after{
                content: '';
                position: absolute;
                top: 35px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                height: 3px;
                background: var(--themeColor);
            }
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width: 120px;
            height: auto;
            -webkit-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
        }
    }
    &.sticky{
        background: var(--darkColor);
        box-shadow: 0px 3px 10px #07758473;
        z-index: 200;
        padding-top: 10px;
        padding-bottom: 10px; 
        .logo{
            svg{
                width: 80px;
                height: auto;
            }
        }
    }
    .toggleBtn{
        display: none;
    }
}
    .whtLngWrapper{
        gap: 7px;
    }
    .headerBtn{
        background-color: var(--themeColor);
        
    }

.Social-links {
    display: flex;
    gap: 12px;
    cursor: pointer;
}
`