import React from 'react';
import styled from "styled-components";
import { FooterBg } from '../Styled/AllImages';


export const FooterWrapper = styled.footer`
  position: relative;
  padding: 40px 0 0 0;
  background-color: var(--darkColor2);
  background-repeat: no-repeat;
  background-image: url(${FooterBg});
  background-size: auto;
  background-position: right -220px top 60px;
	.footerAboutTxt{
		line-height: 24px;
		margin-top: 25px;
	}
  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a {
      color: var(--white);
      /* font-family: var(--semibold); */
      &:hover {
        // color: var(--text);
      }
    }
  }
  .footer-list a {
    color: var(--white);
    padding-bottom: 8px;
    position: relative;
    font-size: 16px;
    font-family: ${(props) =>
		props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
    font-weight: 400;
    cursor: pointer;
  }
  .footer-revised {
    position: relative;
    padding: 25px 25px 25px 25px;
  }
  .fowl {
    cursor: pointer;
    &:hover {
      color: var(--white);
    }
  }
  .footer-logo {
    width: 80px;
  }
  .f-logo-wrap {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .footer-heading {
    font-size: 20px;
    margin-bottom: 20px;
	font-family: var(--lightMedium);
	color: var(--themeColor);
    &::after {
      display: none;
    }
  }
  .footerLinks {
    display: flex;
    align-items: flex-start;
    .footerLinksCol {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      & > div {
        display: flex;
        width: 100%;
      }
      a {
        font-size: 14px;
        color: var(--text);
        font-family: var(--book);
      }
    }
  }
  .cntct-btn {
    margin-bottom: 25px;
    button {
      background-color: transparent !important;
      padding: 0 !important;
      height: auto;
      text-transform: none;
      border: none;
      span {
        padding: 0;
        font-family: var(--book);
        color: var(--text);
        font-size: 14px;
      }
    }
  }
  .footerAddress {
    color: var(--white);
    font-family: var(--regular);
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-family: var(--light);
	margin-bottom: 20px;
    svg {
      min-width: 18px;
      max-width: 18px;
    }
	  .footerAddressWr{
		display: flex;
		flex-wrap: wrap;
	  }
    .footerAddressTitle {
      font-family: var(--medium);
      width: 100%;
    }
  }

  .footerCol1 {
    padding-right: 30px;
  }
  .footerCol2 {
    padding-right: 40px;
    padding-left: 80px;
    padding-top: 40px;
  }
  .footerCol3 {
    // padding-left: 40px;
    padding-top: 40px;
  }
  .footerCol4 {
    padding-left: 40px;
    padding-top: 40px;
  }
  .footerContain{
	padding-bottom: 40px;
  }
	.footerContain2{
	padding-top: 30px;
	padding-bottom: 30px;
	}
	.contactSocial{
			display: flex;
			align-items: center;
			gap: 15px;
			}
      .footer-row{
        display: flex;
        justify-content: space-between;
        & > div{
          width: fit-content;
        }
      }
		.footerCrWrapper{
			background-color: var(--darkColor3);
		}
		
		.footer-op-allRights,
		.footer-op-develop{
			font-family: var(--book);
		}
		.footer-op-company{
			font-family: var(--lightMedium);
			
		}
		@media (max-width: 1399px) {
			.footerCol1 {
				padding-right: calc(var(--bs-gutter-x) * .5);
			}
		}
		@media (max-width: 1199px) {
			.footerCol2 {
				padding-right: calc(var(--bs-gutter-x) * .5);
				padding-left: calc(var(--bs-gutter-x) * .5);
			}
			.footerCol4 {
				padding-left: calc(var(--bs-gutter-x) * .5);
			}
		}
		@media (max-width: 991px) {
			.footerLogo{
				display: flex;
				/* justify-content: center; */
			}
			.footerAboutTxt{
				/* text-align: center; */
			}
			.footer-heading{
				justify-content: flex-start;
				text-align: left;
				font-size: 25px;
			}
		}
		@media (max-width: 380px) {
			.footerCol2,
			.footerCol3{
				width: 100%;
			}
			.footerCol2{
				padding-top: 0px;
			}
		}
		 
		
`;
