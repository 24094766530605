import styled from "styled-components";

export const TrainersStyled = styled.div`
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .package-card {
    position: relative;
    height: 260px;
  }
  .pkg-content {
    z-index: 9;
  }
  .duration-text {
    display: inline-block;
    background-color: rgba(38, 205, 159, 0.3);
  }
  .bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: ${(props) => (props.lang === "ar" ? "scaleX(-1)" : "scaleX(1)")};
  }

`;

export const TrainerDetailStyled = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .experience-container {
    border-bottom: 1px solid var(--borderColor);
  }
`;
