import React, { useEffect, useState } from "react";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import {
  Check,
  ExperienceIcon,
  InfoIcon,
  PagesBg,
} from "../../components/Styled/AllImages";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SubHeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import { TrainerDetailStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetTrainerDetailsQuery } from "../../store/services/trainersApi";
import ImageGallery from "../../components/imageGallery/ImageGallery";

const TrainerDetails = () => {
  const TRAINERS_ASSET_URL = process.env.REACT_APP_TRAINERS_ASSET_URL;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { id } = useParams();
  const [images, setImages] = useState([]);

  const [trainerDetails, setTrainerDetails] = useState({});
  const [certificates, setCertificates] = useState([]);

  const { data, isLoading, isFetching } = useGetTrainerDetailsQuery({
    id,
    lang: language,
  });

  useEffect(() => {
    if (data) {
      setTrainerDetails(data?.data);
      if (
        data?.data?.certificates_images &&
        data?.data?.certificates_images.length > 0
      ) {
        setCertificates(
          data.data.certificates_images.map(
            (certificate) => `${TRAINERS_ASSET_URL}/${certificate.certificates}`
          )
        );
      }
      if (
        data?.data?.trainers_images &&
        data?.data?.trainers_images.length > 0
      ) {
        setImages(
          data?.data?.trainers_images.map(
            (img) => `${TRAINERS_ASSET_URL}/${img.more_images}`
          )
        );
      }
    }
  }, [data]);
  return (
    <>
      <TrainerDetailStyled style={{ backgroundImage: `url(${PagesBg})` }}>
        <Container className="common-container">
          <Row>
            <Col lg={6} md={12}>
              <ImageSlider images={images} />
            </Col>
            <Col lg={6} md={12}>
              <Box>
                <HeadingStyled color="var(--white)" size="20px" weight="500">
                  {trainerDetails?.job}
                </HeadingStyled>
                <SubHeadingStyled size="36px" weight="600">
                  {trainerDetails?.name}
                </SubHeadingStyled>
              </Box>
              <Box className="experience-container py-4">
                <Row>
                  <Col lg={6} md={12}>
                    <Box className="d-flex align-items-center">
                      <InfoIcon />
                      <Box className="mx-3">
                        <HeadingStyled size="16px" weight="500">
                          {t("job_title")}
                        </HeadingStyled>
                        <TextStyled size="16px" weight="300">
                          {trainerDetails?.job}
                        </TextStyled>
                      </Box>
                    </Box>
                  </Col>
                  <Col lg={6} md={12}>
                    <Box className="d-flex align-items-center">
                      <ExperienceIcon />
                      <Box className="mx-3">
                        <HeadingStyled size="16px" weight="500">
                          {t("years_of_experience")}
                        </HeadingStyled>
                        <TextStyled size="16px" weight="300">
                          {trainerDetails?.exp_years &&
                            parseInt(trainerDetails?.exp_years)}{" "}
                          {t("years")}
                        </TextStyled>
                      </Box>
                    </Box>
                  </Col>
                </Row>
              </Box>
              <Box>
                <HeadingStyled size="16px" weight="500" className="mt-4 mb-2">
                  {t("introduction")}
                </HeadingStyled>
                <TextStyled size="16px" weight="300">
                  {trainerDetails?.intro}
                </TextStyled>
                <HeadingStyled size="16px" weight="500" className="mt-4 mb-2">
                  {t("previous_experience")}
                </HeadingStyled>
                <TextStyled size="16px" weight="300">
                  {trainerDetails?.experiences}
                </TextStyled>
                <Box className="py-3">
                  <Row>
                    {certificates.length > 0 &&
                      certificates.map((x, i) => (
                        <Col lg={3} md={6} sm={12} className="my-2">
                          <LazyImage src={x} />
                        </Col>
                      ))}
                    {/* <ImageGallery images={certificates} /> */}
                  </Row>
                </Box>
              </Box>
              <Box>
                <HeadingStyled size="16px" weight="500" className="mt-4 mb-2">
                  {t("certified_certificates")}
                </HeadingStyled>
                {trainerDetails?.certificates &&
                  trainerDetails?.certificates.split("|").length > 0 &&
                  trainerDetails?.certificates.split("|").map((exp, key) => (
                    <Box className="d-flex" key={key}>
                      <Check className="mt-1" />
                      <TextStyled size="16px" weight="300" className="mx-3">
                        {exp}
                      </TextStyled>
                    </Box>
                  ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </TrainerDetailStyled>
    </>
  );
};

export default TrainerDetails;
