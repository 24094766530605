import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, SubHeadingStyled } from '../../components/Styled/Elements'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useGetPrivacyPolicyQuery } from '../../store/services/privacyApi'

const PrivacyPolicy = () => {
    const {t, i18n: {language}} = useTranslation()
    const [htmlContent, setHtmlContent] = useState("")
    const {data, isLoading, isFetching}  = useGetPrivacyPolicyQuery(language)
    useEffect(() => {
        if(data?.data){
            setHtmlContent(data?.data?.field)
        }
    }, [data])
  return (
    <Container className="common-container">
      <Box>
        <SubHeadingStyled size="36px" weight="600">
          {t("privacy_policy")}
        </SubHeadingStyled>
      </Box>
      <Box className="mt-5">
        <Row>
          {isLoading | isFetching ? (
            <Box className="d-flex justify-content-center">
              <Spinner animation="border" className="custom-spinner" />
            </Box>
          ) : (
              <Box className='html-box' dangerouslySetInnerHTML={{__html: htmlContent    }}>
              </Box>
          )}
        </Row>
      </Box>
    </Container>
  )
}

export default PrivacyPolicy