import styled from "styled-components";

export const TrainersWrapper = styled.section`
	padding-top: 0;
	height: 100%;
	.trainersBg {
		mix-blend-mode: soft-light;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	
`;
