import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutImg, AboutImg1, AboutImg2, AboutImg3, AboutImgAr, ArrowRight, ExpertiseBg, Stripe } from '../../Styled/AllImages'
import { ContactButton, PackageCard } from '../../Styled/CustomElements'
import { useNavigate } from 'react-router-dom'
import { ExpertiseWrapper, PackagesWrapper } from './Styled'
import { expertiseList, packages, scrollImages } from '../../data/Data'

const Packages = ({data}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    
    return (
        <>
            <PackagesWrapper className='container-fluid common-space packagesFluid' id='packages'>
                {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
                {/* <Box className='baseLayer baseLayerExpertise'>
                    <img className="expertiseBg" src={ExpertiseBg} />
                </Box> */}
                <Container>
                    <Row className='packagesRow'>
                        <Col md={12}>
                            <SubHeading className='sub-heading'>{t('AchieveBest')}</SubHeading>
                            <MainHeading className='main-heading'>{t('Our_Packages')}</MainHeading>
                        </Col>
                        <Col lg={12} className='sliderWrapper'>
                            <Row>
                                {data && data.length > 0 && data.map((x, i) => (
                                    <Col lg={6} md={6} sm={12}>
                                        <PackageCard data={x}/>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Col className='d-flex justify-content-center mt-4'>
                            <ContactButton size="14px" icon={ArrowRight} label={t('ViewAllPackages')} onClick={() => navigate('/our-packages')} />
                        </Col>
                    </Row>
                </Container>
            </PackagesWrapper>
        </>
    )
}

export default Packages
