import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
export const packagesApi = createApi({
  reducerPath: "packagesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getPackageDetails: builder.query({
      query: ({ id, lang }) => ({
        method: "GET",
        url: `package-detail/${id}`,
        headers: {
          "api-key": API_KEY,
          "X-localization": lang,
        },
      }),
    }),
  }),
});

export const { useGetPackageDetailsQuery } = packagesApi;
