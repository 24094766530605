import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutImg, AboutImg1, AboutImg2, AboutImg3, AboutImgAr, ArrowRight, Stripe, StripeAr } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import { AboutWrapper } from './Styled'
import Expertise from './Expertise'
import { useNavigate } from 'react-router-dom'

const About = ({data}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    /* const WhatsApp = () => {
        const phoneNumber = '';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    }; */
    return (
        <>
            <AboutWrapper className='container-fluid common-space aboutFluid' id='about'>
                {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
                <Box className='baseLayer baseLayerAbout'>
                    <img className="aboutBg" src={AboutBg} />
                    <img className="aboutStripe aboutStripeEn" src={Stripe} />
                    <img className="aboutStripe aboutStripeAr" src={StripeAr} />
                </Box>
                <Container>
                    <Box className='cmn-text-card row'>
                        <Col md={12} lg={6} className='aboutImgCol'>
                            <Box className="aboutImgContainer">
                                <Box className='aboutImgTxt'>{t('ProfessionalTraining')}</Box>
                                <LazyImage className="aboutImg" src={AboutImg1} />
                            </Box>
                            <Box className="aboutImgContainer">
                                <Box className='aboutImgTxt'>{t('Motivation')}</Box>
                                <LazyImage className="aboutImg" src={AboutImg2} />
                            </Box>
                            <Box className="aboutImgContainer">
                                <Box className='aboutImgTxt'>{t('Results')}</Box>
                                <LazyImage className="aboutImg" src={AboutImg3} />
                            </Box>
                            
                            
                        </Col>
                        <Col md={12} lg={6} className='aboutContentCol'>
                            <SubHeading className='sub-heading'>{t('YourPartnerin')}</SubHeading>
                            <MainHeading className='main-heading'>{t('HealthFitness')}</MainHeading>
                            
                            <TextStyled className='commonTxt'>
                                {t('AboutTxt')}
                            </TextStyled>
                            
                            <ContactButton icon={ArrowRight} label={t('show_packages')} clName="centerBtn" onClick={() => navigate('/our-packages')} />
                        </Col>
                    </Box>
                    
                </Container>
            </AboutWrapper>
            <Expertise data={data} />
        </>
    )
}

export default About
